import { RESET_PASSWORD_TOKEN_KEY } from '../constants';

export default {
  login(data) {
    return {
      method: 'POST',
      url: `/v1/auth/phone/mfa`,
      data: {
        ...data,
        role: 'operator'
      }
    };
  },
  confirm2fa(data) {
    return {
      method: 'POST',
      url: `/v1/auth/credentials`,
      data: {
        ...data,
        role: 'operator'
      }
    };
  },
  forgotPassword(data) {
    return {
      method: 'POST',
      url: 'v1/auth/password/reset',
      data,
    };
  },
  resetPassword(data) {
    return {
      method: 'POST',
      url: 'v1/auth/password',
      data,
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem(RESET_PASSWORD_TOKEN_KEY),
      }
    };
  },
  getUserInfo() {
    return {
      method: 'GET',
      url: '/v1/users/me',
    };
  },
  updateUserInfo(data) {
    return {
      method: 'PUT',
      url: '/me',
      data,
    };
  },

  getPanicById(id) {
    return {
      method: 'GET',
      url: `/v2/panic/${id}`,
    };
  },

  getPanicHistory(data) {
    return {
      method: 'GET',
      url: '/v2/panic/history',
      data,
    };
  },

  getActivePanicHistory(data) {
    return {
      method: 'GET',
      url: '/v2/panic/history/active',
      data,
    };
  },

  getWaitingPanicHistory(data) {
    return {
      method: 'GET',
      url: '/v2/panic/history/waiting',
      data,
    };
  },
  getActiveObjectPanicHistory(data) {
    return {
      method: 'GET',
      url: '/v2/panic/share/history/active',
      data,
    };
  },

  getWaitingObjectPanicHistory(data) {
    return {
      method: 'GET',
      url: '/v2/panic/share/history/waiting',
      data,
    };
  },

  assignAgent({ id, data }) {
    return {
      method: 'POST',
      url: `/v2/panic/assign/${id}`,
      data,
    };
  },

  deassignAgent({ id, data }) {
    return {
      method: 'POST',
      url: `/v2/panic/deassign/${id}`,
      data,
    };
  },

  rejectCancelation(id) {
    return {
      method: 'DELETE',
      url: `/v2/panic/cancel/request/${id}`,
    };
  },
  cancelPanic(id) {
    return {
      method: 'POST',
      url: `/v2/panic/cancel/${id}`,
    };
  },
  panicDone(id) {
    return {
      method: 'DELETE',
      url: `v2/panic/${id}`,
    };
  },
  agentInPlace(id) {
    return {
      method: 'POST',
      url: `v2/panic/inplace/${id}`,
    };
  },

  getAgents({ providerIds, ...data }) {
    let providers = '';

    if (providerIds) {
      providers = providerIds.map((x) => `providerIds[]=${x}`).join('&');
    }

    return {
      method: 'GET',
      url: `/v1/providers/agents${providers ? `?${providers}` : ''}`,
      data
    };
  },

  getUsers(data) {
    return {
      method: 'GET',
      url: '/v1/providers/users',
      data,
    };
  },
  uploadAgentPhoto({ photo, id }) {
    const formData = new FormData();
    formData.append('photo', photo);
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `/agent/${id}/photo`,
      data: formData,
    };
  },

  uploadAgentPhotoNew({ photo, providerId, userId }) {
    if (!photo) {
      throw new Error('No photo provided');
    }
    return {
      method: 'POST',
      url: `v1/providers/${providerId}/users/${userId}/photo`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ mimeType: photo.type }),
    };
  },

  uploadToStorage({ photo, id, otc }) {
    const data = new FormData();
    data.append('', photo);

    return {
      method: 'POST',
      url: `v1/storage/upload/${id}/${otc}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data
    };
  },

  createAgent({ providerId, ...data }) {
    return {
      method: 'POST',
      url: `/v1/providers/${providerId}/users`,
      data,
    };
  },
  editAgent({ providerId, userId, ...data }) {
    return {
      method: 'PUT',
      url: `/v1/providers/${providerId}/users/${userId}`,
      data,
    };
  },
  deleteAgent({ providerId, userId }) {
    return {
      method: 'DELETE',
      url: `/v1/providers/${providerId}/users/${userId}`,
    };
  },

  setLanguage({ lang }) {
    return {
      method: 'PATCH',
      url: '/v1/users/settings',
      data: {
        language: lang
      },
    };
  },

  getUserSettings() {
    return {
      method: 'GET',
      url: '/v1/users/theme',
    };
  },
  updateUserSettings(data) {
    return {
      method: 'PATCH',
      url: '/v1/users/theme',
      data,
    };
  },
  getAvailableAgents() {
    return {
      method: 'GET',
      url: '/v1/providers/agents/availability',
    };
  },
  getPanicRouteHistory(panicId) {
    return {
      method: 'GET',
      url: `/v2/panic/location/${panicId}`,
    };
  }

};
