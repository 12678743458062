import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  clientCard: {
    boxShadow: '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
    marginBottom: '8px',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardMedia: {
    height: 150,
    width: 150,
    borderRadius: 8,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  image: {
    height: 80,
    width: 80,
    borderRadius: 8,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer',
  },
  dialogImage: {
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  navigationButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  leftButton: {
    left: 0,
  },
  rightButton: {
    right: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
}));

export default useStyles;
