import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  status: {
    padding: theme.spacing(0.1, 1),
    borderRadius: theme.shape.borderRadius,
    color: 'white',
    fontWeight: 'bold',
    display: 'inline-block',
    textAlign: 'center',
    backgroundColor: 'gray',
    fontSize: '15px'
  },
}));

export default useStyles;
