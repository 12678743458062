import { makeStyles } from '@material-ui/core/styles';

const useAgentModalStyles = makeStyles(() => ({
  modalContainer: {
    background: '#2b2b2b',
    color: '#fff',
    width: '500px',
    padding: '30px',
    borderRadius: '8px',
    margin: 'auto',
    marginTop: '10%',
    position: 'relative',
  },
  title: {
    marginBottom: '8',
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    color: '#AEAEAE',
  },
  radioLabel: {
    '& .MuiRadio-root.Mui-checked': {
      color: '#00ff00',
    },
    '&.MuiFormControlLabel-root.Mui-checked .MuiFormControlLabel-label': {
      color: '#00ff00',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      color: '#fff',
    },
  },
  modalBody: {
    display: 'grid',
    justifyContent: 'space-around',
    marginTop: 40,
    marginBottom: 20
  },
  inputLabel: {
    '& .MuiOutlinedInput-root': {
      borderColor: '#AEAEAE',
      borderRadius: 9,
      '& input': {
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#AEAEAE',
      },
    },
  },
  inputLabelError: {
    '& .MuiOutlinedInput-root': {
      borderColor: '#AEAEAE',
      borderRadius: 9,
      '& input': {
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#EC2A36',
      },
    },
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#fff',
  },
  assignButton: {
    backgroundColor: '#fff',
    color: '#000',
    fontWeight: 'bold',
    marginTop: '16px',
    textTransform: 'none',
  },
}));

export default useAgentModalStyles;
