import React, { useState } from 'react';
import {
  Box,
  Chip,
  Avatar,
  Typography, MenuItem, Menu,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { AgentSelect } from 'components/input';
import useStyles from '../styles';
import ClientCard from '../client-card';
import PanicTimeline from '../timeline';
import ClientComment from '../client-comment-card';
import AgentComment from '../aget-comment-card';
import { formatPhoneNumber } from 'utils/formatPhone';
import { useFile } from 'utils/get-file';
import { IAppState, PanicStatus } from 'types';
import { Loader } from 'components';
import { RESOLVED_PANICS } from 'constants/panic';
import StatusBadge from '../../../components/status-badge';
import ObjectCard from '../object-card';
import AssignComponent from '../../../components/assign-agent';
import { deassignAgentRequest } from '../../../actions/panic';

type Props = {
  toggleModal: () => void;
  panicId: number;
}

const SideInfo = ({
  toggleModal,
  panicId,
}: Props) => {
  const dispatch = useDispatch();
  const { panic, agentListById, client, agent, auth } = useSelector(({ panic, family, auth }: IAppState) => ({
    agentListById: family.byId,
    panic: (panic.byId[panicId] || {}),
    client: (panic.byId[panicId] || {})?.user,
    agent: (panic.byId[panicId] || {})?.agent,
    auth: auth.data.providers[0]
  }));
  const disableAgentSection = useSelector(({ panic }: IAppState) =>
    panic.pendingAssignment.indexOf(panic.selectedPanicId) !== -1,
  );
  const classes = useStyles();
  const isPanicStatusOngoing = !RESOLVED_PANICS.includes(panic?.status);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isImageEnabled = useSelector(({ settings }: IAppState) => settings.isImageEnabled);

  const agentPhoto = useFile(agent?.photo && isImageEnabled? `v1/storage/file/public/${agent.photo.uuid}` : '');

  const isViewOnlyAgent = (agentId: number) => {
    // @TODO: Discuss with the team what view only means
    const isViewOnly = agentListById[agentId];

    return false || isViewOnly;
  };

  const handleAgentUnassign = async (agentId: number) => {
    const params = {
      id: panicId,
      createdByProvider: auth.id === Number(panic.createdByProviderId),
      isObjectPanic: true,
      data: {
        agentId,
      }
    };
    await dispatch(deassignAgentRequest({
      params
    }))

    if (auth.id !== Number(panic.createdByProviderId)) {
      toggleModal()
    }
  };

  return (
    <>
      <Box className={classes.cardStyles} width="100%" position="relative">
        <Loader
          isLoading={disableAgentSection}
          inContainer
          text={<FormattedMessage id="agent.pending_assign" defaultMessage="Assigning Agent" />}
        />
        <Typography variant="body2">
          {panic?.agentId ? (
            <FormattedMessage id="agent.assigned" defaultMessage="Assigned Agent" />
          ) : (
            isPanicStatusOngoing && <FormattedMessage id="mapPage.selectAgentLabel" defaultMessage="Select Agent" />
          )}
        </Typography>
        {panic?.status === PanicStatus.deassigned && panic.objectId ? (
          <AssignComponent panic={panic} />
        ) : (
          <>
            {Boolean(panic?.status) &&
              (isPanicStatusOngoing && !panic.objectId ? (
                <AgentSelect
                  assignedAgentId={panic?.agentId}
                  panicId={panic?.id}
                  //@ts-expect-error lol
                  isViewOnlyAgent={isViewOnlyAgent}
                  panicInPlace={panic?.inPlaceAt}
                  panicAcceptedAt={panic?.acceptedAt}
                  panicStatus={panic?.status}
                  isPanicStatusOngoing={isPanicStatusOngoing}
                  toggleModal={toggleModal}
                />
              ) : (
                agent?.fullName && [PanicStatus.assigned, PanicStatus.accepted].includes(panic.status) && (
                  <>
                    <Box margin="16px 16px 16px 0" clone>
                      <Chip
                        label={agent?.fullName}
                        avatar={<Avatar src={agentPhoto} />}
                        color="default"
                        variant="default"
                        onClick={handleClick}
                      />
                    </Box>
                    <Menu
                      className={classes.cardStyles}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      getContentAnchorEl={null}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleAgentUnassign(agent.id);
                          handleClose();
                        }}
                      >
                        <FormattedMessage id="providerObject.deassign" />
                      </MenuItem>
                    </Menu>
                  </>
                )
              ))}
          </>
        )}
        {Boolean(panic?.agentId) && (
          <Box mt={1}>
            {panic.objectId && agent ? (
              <>
                <Box>
                  <Typography variant="body1">
                    <span className={classes.nameContainer}>
                      {panic.provider?.name}
                      <StatusBadge status={panic.status} />
                    </span>
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <Typography variant="body1">
                    {`${agent?.fullName}, `}
                    {agent?.phone && (
                      <a href={`tel:${agent.phone}`} className={classes.callTo}>
                        +{formatPhoneNumber(agent.phone)}
                      </a>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1">
                    <FormattedMessage id="common.eta_time" />
                    {`: ${panic.agentEstimatedArrival || '-'}`}
                    min
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
      {panic.objectId ? (
        <ObjectCard objectData={panic.providersObject || {}} />
      ) : (
        <ClientCard clientData={client || {}} />
      )}
      {!panic.objectId && <ClientComment comment={panic?.comment} media={panic?.mediaContents} />}
      <PanicTimeline client={client} agent={agent} panic={panic} />
      <AgentComment panicData={panic} />
    </>
  );
};




export default SideInfo;
