import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  appBar: {
    position: 'sticky',
  },
  toolbar: {
    justifyContent: 'flex-end',
    gap: 16,
    '& button': {
      boxShadow: `0 0 0 2px #AEAEAE`,
      '&.close-icon': {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      '&:hover': {
        boxShadow: `0 0 0 2px #FFF`,
      },
    },
  },
  closeIcon: {
    boxShadow: 'none',
  },
  paperHeader: {
    fontSize: '1.3rem',
    textTransform: 'capitalize',
  },
  primaryItem: {
    textTransform: 'capitalize',
  },
  userImg: {
    maxWidth: 100,
    width: '100%',
    height: 75,
    objectFit: 'cover',
  },
  mapStyles: {
    overflow: 'hidden',
    width: '100%',
    height: 'calc(100vh - 64px - 60px)',
    position: 'relative',
    // "& *": {
    //   width: "100%",
    // },

    '& *[class*="__content-inner"]': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& *[class*="controls__control"]': {
      width: 'auto',
    },
    '& *[class*="ymap"]': {
      // height: "100% !important",
    },
    '&:after': {
      content: "''",
      display: 'block',
      paddingBottom: '100%',
    },
  },
  mapInModal: {
    // 64px - header height, 32px - vertical padding
    height: 'calc(100vh - 64px - 32px)',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
  },
  listStyles: {
    width: '100%',
  },
  cardStyles: {
    marginBottom: '8px',
    padding: '8px 16px',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  callTo: {
    color: 'inherit',
    textDecoration: 'none',
  },
  cancelRequestTitle: {
    color: '#FAE24C',
    fontWeight: 'bold',
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  }
});

export default useStyles;
