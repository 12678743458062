import { makeStyles } from '@material-ui/core';

import { gray, lightGray } from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  container: (props: { disabled?: boolean; variant?: string }) => ({
    position: 'relative',
    borderRadius: 26,
    backgroundColor: props.variant === 'yellow' && !props.disabled ? '#FFD700' : 'white',
    color: !props.disabled ? '#000' : 'inherit',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      margin: !props.disabled ? 0 : undefined,
      backgroundColor:
        props.variant === 'yellow' && !props.disabled ? '#FFC700' : undefined, // Darker yellow on hover
      '&:after': {
        display: !props.disabled ? 'none' : undefined,
      },
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: lightGray,
      border: `2px solid ${gray}`,
    },
  }),
}));


export default useStyles;
