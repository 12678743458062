import React from 'react';
import useStyles from './styles';
import { PanicStatus } from '../../types';
import { statusColors } from '../../constants/colors';

interface StatusProps {
  status: PanicStatus;
}

const StatusBadge: React.FC<StatusProps> = ({ status }) => {
  const classes = useStyles();

  const backgroundColor = statusColors[status] || 'gray';

  return (
    <span
      className={classes.status}
      style={{ backgroundColor }}
    >
      {status.replace('_', ' ').toUpperCase()}
    </span>
  );
};

export default StatusBadge;
