import React, { useState, useEffect } from 'react';
import { Box, Grid, CardMedia, Typography, DialogContent, Dialog, IconButton } from '@material-ui/core';
import useStyles from './styles';
import getFile from 'utils/get-file';
import { IAppState, IProviderObject } from 'types';
import { useSelector } from 'react-redux';
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';


type Props = {
  objectData: IProviderObject;
};

const ObjectCard = ({ objectData }: Props) => {
  const [addressImages, handleAddressImages] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const isImageEnabled = useSelector(({ settings }: IAppState) => settings.isImageEnabled);

  const openDialog = (image: number) => {
    setCurrentIndex(image);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setCurrentIndex(null);
    setIsOpen(false);
  };

  const handleNext = () => {
    if (currentIndex !== null && addressImages.length) {
      setCurrentIndex((currentIndex + 1) % addressImages.length);
    }
  };

  const handlePrevious = () => {
    if (currentIndex !== null && addressImages.length) {
      setCurrentIndex((currentIndex - 1 + addressImages.length) % addressImages.length);
    }
  };


  useEffect(() => {
    if (objectData?.photos?.length && isImageEnabled) {
      const addressRequests = objectData.photos
        .map((photo) => getFile(`v1/storage/file/protected/${photo}`));

      addressRequests.length &&
        Promise.all(addressRequests).then((data) => {
          if (data) {
            handleAddressImages(data.map((image) => URL.createObjectURL(image)));
          }
        });
    }
  }, [objectData?.photos]);

  const classes = useStyles();

  if (objectData) {
    return (
      <Box className={classes.clientCard}>
        <Box mb={2}>
          <Typography variant="body2">
            <FormattedMessage id="common.object_info" />
          </Typography>
        </Box>

        <Grid container className={classes.mainContent} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Typography variant="h5">
                <strong>{objectData?.code || '-'}</strong>
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body2">
                <FormattedMessage id="providerObject.name" />:{' '}
                <strong>{objectData?.name || '-'}</strong>
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body2">
                <FormattedMessage id="providerObject.phone" />:{' '}
                <strong>{objectData?.phone || '-'}</strong>
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body2">
                <FormattedMessage id="providerObject.address" />:{' '}
                <strong>{objectData?.address || '-'}</strong>
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body2">
                <FormattedMessage id="providerObject.comment" />:{' '}
                <strong>{objectData?.comment || '-'}</strong>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} className={classes.imageGrid}>
            {addressImages?.length > 0 ? (
              addressImages.map((img, index) => (
                <Grid item md={3} key={index}>
                  <CardMedia
                    component="img"
                    className={classes.image}
                    image={img}
                    alt={`Image-${index}`}
                    onClick={() => openDialog(index)}
                  />
                </Grid>
              ))
            ) : (
              <Typography>
                <FormattedMessage id="providerObject.no_images" />
              </Typography>
            )}
          </Grid>


          <Dialog open={isOpen} onClose={closeDialog} maxWidth="xl">
            <DialogContent className={classes.dialogContent}>
              <IconButton
                className={classes.closeButton}
                onClick={closeDialog}
                aria-label="Close"
              >
                <Close />
              </IconButton>

              <IconButton
                className={`${classes.navigationButton} ${classes.leftButton}`}
                onClick={handlePrevious}
                aria-label="Previous"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                className={`${classes.navigationButton} ${classes.rightButton}`}
                onClick={handleNext}
                aria-label="Next"
              >
                <ArrowForward />
              </IconButton>

              {currentIndex !== null && (
                <img
                  src={addressImages[currentIndex]}
                  alt={`Fullscreen Image ${currentIndex}`}
                  className={classes.dialogImage}
                />
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </Box>
    );
  }
  return null;
};

export default ObjectCard;
