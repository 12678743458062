import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { StyledButton } from '../common';
import { IPanicObjectProps, IProviderObject } from '../../types';
import api from '../../api';
import { FormattedMessage } from 'react-intl';
import { AddObjectAgent } from '../modal';

interface PanicProps {
  panic?: IPanicObjectProps;
}

interface Agent {
  agentId: string;
  name: string;
  duration: string;
  distance: string;
  providerId: number
  providerName: string
}

const AssignComponent: React.FC<PanicProps> = ({ panic }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [agentsList, setAgents] = useState<Agent[]>([]);
  const [providerObject, setProviderObject] = useState<IProviderObject>();
  useEffect(() => {
    const fetchAgents = async () => {
      if (panic?.providersObject?.code) {
        try {
          const { agents, providersObject } = await api.request(
            'get',
            `${api.apiBase}/v1/providers-object/panic/agents?code=${panic.providersObject.code}`
          );

          if (agents?.length && providersObject) {
            setAgents(agents);
            setProviderObject(providersObject);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching agents:', error);
        }
      }
    };

    fetchAgents();
  }, [panic]);

  return (
    <Box style={{ marginRight: '50px' }}>
      <StyledButton
        variant={agentsList.length ? 'yellow' : 'contained' }
        height={false}
        onClick={() => setModalOpen(true)}
      >
        <FormattedMessage id={panic ? 'providerObject.new_agent' : 'providerObject.new_panic'} />
      </StyledButton>
      <AddObjectAgent
        isOpen={isModalOpen}
        isActive={!!panic?.providersObject?.code}
        onClose={() => setModalOpen(false)}
        panic={panic}
        providerObject={providerObject}
        agents={agentsList}
      />
    </Box>
  );
};

export default AssignComponent;
