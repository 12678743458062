import { useRef, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { IAppState } from 'types';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

const panicAudio = new Audio();
const audioContext = new AudioContext();
panicAudio.loop = true;
panicAudio.volume = 1;

const snackbarName = 'panic-audio-error';

/* devnotes:
  - panicAudioRef is a reference to the panicAudio object - please use it instead of the panicAudio object
  - hasInteracted is a reference to a boolean that checks if the user has interacted with the DOM

  Sound can be blocked by the browser for a few reasons, for now we are covering the scenario when the user didn't interact with the DOM yet.
*/

const usePanicAudio = () => {
  const [isAudioFileLoaded, setAudioFileLoaded] = useState(false);
  const [currentPlayPauseAction, setPlayPauseAction] = useState<'play' | 'pause'>('pause');

  const { formatMessage } = useIntl();
  const panicAudioRef = useRef(panicAudio);
  const checkForAtLeastOnePendingPanicRef = useRef(false);
  const pendingPanicsList = useSelector(({ panic: { waitingPanicIds, objectWaitingPanicIds, pendingAssignment, byId } }: IAppState) => {
    const allWaitingPanicIds = [...waitingPanicIds, ...objectWaitingPanicIds];

    return allWaitingPanicIds
      .filter((id) => pendingAssignment.indexOf(id) === -1)
      .map((id) => byId[id]);
  }
  );
  const soundVolume = useSelector(({ settings }: IAppState) => settings.soundVolume);

  const shouldPlayAudioAfterDomInteraction = pendingPanicsList.length > 0;
  checkForAtLeastOnePendingPanicRef.current = !!shouldPlayAudioAfterDomInteraction;

  useEffect(() => {
    panicAudioRef.current.volume = soundVolume;
  }, [soundVolume]);

  const handleAudioPlay = () => {
    if (panicAudioRef.current.paused) {
      const promise = panicAudioRef.current.play();
      if (promise !== undefined) {
        promise.then(() => {
          // Autoplay started!
        }).catch((error) => {
          // Autoplay was prevented.
          global.console.log('[PANIC AUDIO] - error :', { error, audioContext });
          if (audioContext.state === 'suspended') {
            enqueueSnackbar(formatMessage({
              id: 'common.no_sound_error',
              defaultMessage: 'Error playing sound. Interaction with the page is expected',
            }), {
              key: snackbarName,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              persist: true,
              preventDuplicate: true,
              style: {
                textAlign: 'center'
              }
            });
            document.addEventListener('click', onDomClickListener, { once: true });
          }
        });
      }
    }
  };

  const onDomClickListener = () => {
    closeSnackbar(snackbarName);
    if (checkForAtLeastOnePendingPanicRef.current) {
      // note: potentially should also check if currentPlayPauseAction === 'play' instead of the old condition
      panicAudioRef.current.play()
    }
    document.removeEventListener('click', onDomClickListener);
  };

  const handleAudioStop = () => {
    if (!panicAudioRef.current.paused) {
      panicAudioRef.current.pause();
    }
  };

  useEffect(() => {
    if (isAudioFileLoaded) {
      if (currentPlayPauseAction === 'play') {
        handleAudioPlay();
      }

      if (currentPlayPauseAction === 'pause') {
        handleAudioStop();
      }
    }
  }, [isAudioFileLoaded, currentPlayPauseAction]);

  useEffect(() => {
    fetch('/panic-audio.wav')
      .then((data) => data.arrayBuffer())
      .then((arrayBuffer) => {
        const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);
        panicAudioRef.current.src = url;
        setAudioFileLoaded(true);
      })

    // disable play/pause from keyboard media keys
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('play', () => {
        global.console.log('Play key pressed but ignored. See usePanicAudio.ts');
      });

      navigator.mediaSession.setActionHandler('pause', () => {
        global.console.log('Pause key pressed but ignored. See usePanicAudio.ts');
      });
    }

    return () => {
      handleAudioStop()
      closeSnackbar()
    }
  }, []);

  return {
    playSound: () => setPlayPauseAction('play'),
    stopSound: () => setPlayPauseAction('pause'),
  };
};

export default usePanicAudio;
