import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState, IProviderObject } from 'types';
import StyledMarkerContent from '../styled-marker-content';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { FormattedMessage } from 'react-intl';

const ObjectMarker = ({ panicId }: { panicId: number }) => {
  const panicDetailsSelector = (state: IAppState) => state.panic.byId[panicId] || {};
  const panicDetails = useSelector(panicDetailsSelector);
  const { providersObject = {} as IProviderObject } = panicDetails;
  if (!providersObject.location) {
    return null;
  }
  const formattedLocation = {
    lat: providersObject.location.coordinates[0],
    lng: providersObject.location.coordinates[1]
  };

  return (
    <AdvancedMarker
      position={formattedLocation}
    >
      <StyledMarkerContent status={'gray'}>
        <FormattedMessage id="common.object" />
      </StyledMarkerContent>
    </AdvancedMarker>

  );
};

export default ObjectMarker;
