import React from 'react';
import { Button } from '@material-ui/core';
import useStyles from './styles';

interface Props {
  size?: 'large' | 'medium' | 'small';
  variant?: 'contained' | 'outlined' | 'text' | 'yellow';
  fullWidth?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  height?: boolean;
  [key: string]: any;
}

const StyledButton = ({
  size = 'medium',
  fullWidth,
  children,
  variant = 'outlined',
  height = true,
  ...rest
}: Props) => {
  const { container } = useStyles({ disabled: rest.disabled, variant });
  return (
    <div style={ height ? { height: 52 } : undefined}>
      <Button
        className={container}
        size={size}
        variant={variant === 'yellow' ? 'contained' : variant}
        fullWidth={fullWidth}
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};

export default StyledButton;
