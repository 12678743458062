import React, { ComponentType, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LS_TOKEN_KEY } from '../constants';
import {
  getActivePanics,
  getWaitingPanics,
  getPanicById,
  removePanicById,
  togglePanicDetailsModal, getActiveProviderObjectPanics, getWaitingProviderObjectPanics,
} from 'actions/panic';
import { getAvailableAgentsRequest,  updateSingleAgentCoords } from 'actions/agent';
import { IAppState, IPanicObjectProps, PanicStatus } from '../types';
import { notificationTypes } from '../constants/colors';
import PanicNotification, { PanicNotificationActions } from './panic-notification';
import { sendLogToCrm } from '../utils/crm-utils';
import usePanicAudio from '../hook/usePanicAudio';
import { updateClientCoords } from 'actions/client';
import { WAITING_PANICS } from 'constants/panic';
import { setSocketReadyState } from 'actions/settings';

const wsUrl = process.env.REACT_APP_WS_URL;

let panicIdsSentToAmoCrm: number[] = JSON.parse(localStorage.getItem('panicIdsSentToAmoCrm') ?? '[]') || [];


interface StateProps {
  tokenFromState: string;
}

type Props = StateProps;

const WebSocketClient = ({
  tokenFromState,
}:Props): null => {
  const displayedPanicsRef = useRef<Set<number>>(new Set());
  const socketRef = useRef<WebSocket | null>(null);
  const token = tokenFromState || window.localStorage.getItem(LS_TOKEN_KEY);
  const tokenUrl = `${wsUrl}?token=${token}`;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const providerIds = useSelector(({ auth }: IAppState) => auth.data.providers.map((provider) => provider.id));
  const sharePanics = useSelector(({ auth }: IAppState) => auth.data.providers.map((provider) => provider.sharePanics));
  const pendingPanicsList = useSelector(({ panic: { waitingPanicIds, pendingAssignment, byId, objectWaitingPanicIds } }: IAppState) => {
    const allWaitingPanicIds = [...waitingPanicIds, ...objectWaitingPanicIds];

    return allWaitingPanicIds
      .filter((id) => pendingAssignment.indexOf(id) === -1)
      .map((id) => byId[id]);
  }
  );
  const dispatch = useDispatch();

  const { playSound, stopSound } = usePanicAudio()

  const setPendingPanic = useCallback((pendingPanics: IPanicObjectProps[]) => {

    pendingPanics.forEach((pendingPanic) => {
      if (!displayedPanicsRef.current.has(pendingPanic.id)) {
        displayedPanicsRef.current.add(pendingPanic.id);
        playSound();
        enqueueSnackbar(
          <PanicNotification
            notifKey={`pending_panic_${pendingPanic.id}`}
            panicId={pendingPanic.id}
            status={pendingPanic.status}
          />,
          {
            key: `pending_panic_${pendingPanic.id}`,
            variant: notificationTypes[pendingPanic.status],
            action: (key) => (
              <PanicNotificationActions notifKey={key} withDetails id={pendingPanic.id} />
            ),
            style: { padding: '0px 10px' },
            persist: true,
            preventDuplicate: true,
          }
        );
      }
      if (!panicIdsSentToAmoCrm.includes(pendingPanic.id)) {
        panicIdsSentToAmoCrm = [...panicIdsSentToAmoCrm, pendingPanic.id];
        localStorage.setItem('panicIdsSentToAmoCrm', JSON.stringify(panicIdsSentToAmoCrm));
        sendLogToCrm({
          fields: {
            837855: pendingPanic.id,
            837859: pendingPanic.user.id,
            837861: 'ip not found',
            837865: 'operator',
          },
          contact: {
            // @TODO: INCLUDE THE PLAN OWNER PHONE HERE
            // @RE-TODO Added owner phone or empty string if inFamily has no elements
            phone: pendingPanic.user.inFamily.find((x) => x.user?.plan?.isActive)?.user?.phone || '',
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (pendingPanicsList.length > 0) {
      setPendingPanic(pendingPanicsList);
    } else {
      stopSound();
    }
    /* TODO: need to update the dependecy to something else,
       currently it's triggering every time panic.byId is updated,
       even if it didn't change.
       A solution would be to keep track of pending panics in the store
       RE-TODO: added temp solution with JSON.stringify
    */
  }, [JSON.stringify(pendingPanicsList)]);

  const handleWsInit = () => {
    // todo maybe update to the socket.io
    const socket = new WebSocket(tokenUrl);

    socket.onmessage = ({ data }) => handleWsMessage(data);

    socket.onopen = () => {
      dispatch(setSocketReadyState(WebSocket.OPEN))
    };

    socket.onclose = () => {
      dispatch(setSocketReadyState(WebSocket.CLOSED))
    };

    socketRef.current = socket;
  };

  // todo: add types to data
  const handleWsMessage = (data: any) => {
    const dataJSON = JSON.parse(data);
    if (!localStorage.getItem(LS_TOKEN_KEY)) {
      return;
    }
    const panicIdFromSocket = dataJSON.id || Number(dataJSON.panicId);
    // agent coords
    if (dataJSON.type === 'agent' && dataJSON.agentId) {
      const timeISO = moment().toISOString();
      dispatch(updateSingleAgentCoords({ ...dataJSON, timeISO, online: true }));
    }
    // client coords
    // for example see import { ClientLiveCoords } from 'types'
    // note on ios the message somethimes doesnt include lat and lon
    if (dataJSON.type === 'client' && dataJSON.clientId && dataJSON.lat && dataJSON.lon) {
      dispatch(updateClientCoords(dataJSON));
    }
    // in case 2 or more operators (in the same area) assign an agent in the first 15(approximate) seconds of the panic creation
    // the backend will decide which one is more suited and the rest will have the panic removed from the list
    //   {
    //     "to": "3", (userProviders[0].providerId)
    //     "type": "panic.status",
    //     "value": "race_lost",
    //     "id": number (panic id)
    // }
    if (dataJSON.type === 'panic.status' && dataJSON.value === 'race_lost') {
      // remove panic from list since it's no longer available for the current operator
      dispatch(removePanicById(dataJSON.id));
      // close modal
      // todo: handle case where another panic is opened, currently this will close any opened modal
      dispatch(togglePanicDetailsModal({ show: false }));
      // get agents availability,
      // note: using dispatch(setAgentAvailable()) to update the array withouth a network request is not posible due to missing agentId in the socket message
      dispatch(getAvailableAgentsRequest());
      // close pending panic snackbar
      closeSnackbar(`pending_panic_${dataJSON.id}`);
      displayedPanicsRef.current.delete(dataJSON.id);
      // add snackbar
      enqueueSnackbar(<PanicNotification notifKey={dataJSON.id} panicId={dataJSON.id} status={dataJSON.value} />, {
        variant: notificationTypes[dataJSON.value as 'race_lost'],
        action: (key) => <PanicNotificationActions notifKey={key} id={dataJSON.id} />,
        style: { padding: '0px 10px' },
      });

    }

    // update panic on status change
    if (dataJSON.value !== 'race_lost' && providerIds.includes(Number(dataJSON.to)) && !dataJSON.panic) {
      dispatch(getPanicById(panicIdFromSocket));
      // get agents availability
      // note: using dispatch(setAgentAvailable() or setAgentBusy()) would be better but is not posible due to missing agentId
      dispatch(getAvailableAgentsRequest());

      if (WAITING_PANICS.includes(dataJSON.value)) {
        // note: might be redundant since we already getPanicById
        // dispatch(getWaitingPanics({ offset: 50 }));
      } else {
        closeSnackbar(`pending_panic_${panicIdFromSocket}`);
        displayedPanicsRef.current.delete(panicIdFromSocket);
        enqueueSnackbar(<PanicNotification notifKey={panicIdFromSocket} panicId={panicIdFromSocket} status={dataJSON.value} />, {
          variant: notificationTypes[dataJSON?.value as PanicStatus],
          action: (key) => <PanicNotificationActions notifKey={key} id={panicIdFromSocket} />,
          style: { padding: '0px 10px' },
        });
      }
    } else if (dataJSON.value === 'deassigned' && dataJSON.panic?.objectId) {
      const createdByProviderId = Number(dataJSON.panic.createdByProviderId);
      const isNotProvider = !providerIds.includes(createdByProviderId);

      if (isNotProvider) {
        dispatch(removePanicById(dataJSON.id));
        dispatch(togglePanicDetailsModal({ show: false }));
        playSound();

        return enqueueSnackbar(
          <PanicNotification
            notifKey={`pending_panic_${dataJSON.panic.id}`}
            panicId={dataJSON.panic.id}
            status={dataJSON.panic.status}
          />,
          {
            key: `pending_panic_${dataJSON.panic.id}`,
            variant: notificationTypes[dataJSON.panic.status as PanicStatus],
            action: (key) => <PanicNotificationActions notifKey={key} id={dataJSON.id} />,
            style: { padding: '0px 10px' },
            persist: true,
            preventDuplicate: true,
          }
        );
      }

      dispatch(getPanicById(panicIdFromSocket));
      dispatch(togglePanicDetailsModal({ show: false }));
      playSound();

      enqueueSnackbar(
        <PanicNotification
          notifKey={`pending_panic_${dataJSON.panic.id}`}
          panicId={dataJSON.panic.id}
          status={dataJSON.panic.status}
        />,
        {
          key: `pending_panic_${dataJSON.panic.id}`,
          variant: notificationTypes[dataJSON.panic.status as PanicStatus],
          action: (key) => <PanicNotificationActions notifKey={key} withDetails id={dataJSON.panic.id} />,
          style: { padding: '0px 10px' },
          persist: true,
          preventDuplicate: true,
        }
      );
    }
  };


  const visibilityChangeHandler = () => {

    // handle case after pc goes to sleep and socket is closed automatically
    // when tab will be visible again, we will try to reconnect
    if (document.visibilityState === 'visible' && socketRef.current?.readyState === WebSocket.CLOSED) {
      // refresh panics data after socket was closed
      dispatch(getActivePanics({ offset: 50 }));
      dispatch(getWaitingPanics({ offset: 50 }));
      if (sharePanics) {
        dispatch(getActiveProviderObjectPanics({ offset: 50 }));
        dispatch(getWaitingProviderObjectPanics({ offset: 50 }));
      }
      // open a new ws connection
      handleWsInit();
    }
  }

  useEffect(() => {
    handleWsInit();
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
      try {
        socketRef.current?.close();
      } catch (err) {
        global.console.log('Error in closing socket: ', err);
      }
    };
  }, []);

  return null;
};

const mapStateToProps = ({ auth }: IAppState): StateProps => ({
  tokenFromState: auth.onLoginToken,
});

// todo: remove hocs
export default compose<ComponentType>(withRouter, connect(mapStateToProps))(WebSocketClient);
