import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineContent, TimelineDot } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import useStyles from './styles';
import { IPanicObjectProps, PersonDetailsType } from 'types';

type Props = {
  client: PersonDetailsType;
  panic: IPanicObjectProps;
  agent: PersonDetailsType;
}

type TimelineItemProps = {
  label: 'createdAt' | 'assignedAt' | 'acceptedAt' | 'inPlaceAt' | 'extendedAt' | 'savedAt' | 'canceledRequestAt' | 'closedAt';
  role: 'client' | 'operator' | 'agent';
  info?: string;
}

const PanicTimeline = ({ client, panic, agent }: Props) => {
  const { timeline, timelineItem, timelineContent, cardHeader } = useStyles();

  const panicCreatedAt = panic?.createdAt || undefined;
  const panicClosedAt = panic?.closedAt|| undefined;

  const clientFullname =  [
    client?.info?.firstName,
    client?.info?.midName,
    client?.info?.lastName,
  ]
    .filter(Boolean)
    .join(' ') || client?.fullName;
  const agentFullname =  [
    agent?.info?.firstName,
    agent?.info?.midName,
    agent?.info?.lastName,
  ]
    .filter(Boolean)
    .join(' ') || agent?.fullName;

  const timelineItems: TimelineItemProps[] = [
    {
      label: 'createdAt',
      role: panic.objectId ? 'operator' : 'client',
      info: clientFullname,
    },
    { label: 'assignedAt', role: 'operator' },
    {
      label: 'acceptedAt',
      role: 'agent',
      info: agentFullname,
    },
    {
      label: 'inPlaceAt',
      role: 'agent',
      info: agentFullname,
    },
    {
      label: 'extendedAt',
      role: 'client',
      info: clientFullname,
    },
    {
      label: 'savedAt',
      role: 'agent',
      info: agentFullname,
    },
    {
      label: 'canceledRequestAt',
      role: 'client',
      info: clientFullname,
    },
    {
      label: 'closedAt',
      role: panic?.closedBy === 'operator' ? 'operator' : 'client',
      info: panic?.closedBy === 'operator' ? undefined : clientFullname,
    },
  ];

  return (
    <>
      <Timeline className={timeline}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2.5} className={cardHeader}>
          <Box>
            <Typography variant="body2">
              <FormattedMessage id="common.call_duration" />
            </Typography>
          </Box>
          <Box color="white">
            <Typography variant="body2">
              {`${moment(panicClosedAt).diff(moment(panicCreatedAt), 'minutes')} min`}
              {moment(panicClosedAt).diff(moment(panicCreatedAt), 'minutes') < 10 ? ` ${moment(panicClosedAt).diff(moment(panicCreatedAt), 'seconds') % 60} sec` : null}
            </Typography>
          </Box>
        </Box>
        {timelineItems
          .filter(({ label }) => panic?.[label])
          .sort((a, b) => moment(panic[a.label]).diff(moment(panic[b.label])))
          .map(({ label, role, info = '' }) => (
            <TimelineItem key={label} className={timelineItem}>
              <TimelineSeparator>
                <TimelineDot variant="outlined" />
                {/* <TimelineConnector /> */}
              </TimelineSeparator>
              <TimelineContent className={timelineContent}>
                <Box>
                  <FormattedMessage id={`panicLog.${label}`} defaultMessage={label} />
                  {' - '}
                  <b>
                    <FormattedMessage id={`common.${role}`} defaultMessage={role} />
                  </b>
                  {info.replace(' ', '').length ? ' - ' : ''}
                  <b>{info}</b>
                </Box>
                <Box>{moment(panic?.[label]).format('DD-MM-YYYY H:mm:ss')}</Box>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </>
  );
};

export default PanicTimeline;
