import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Modal, IconButton, TextField, FormHelperText,
} from '@material-ui/core';

import useAgentModalStyles from './styles'
import CloseIcon from '@material-ui/icons/Close';
import api from '../../../api';
import { useDispatch } from 'react-redux';
import { assignAgentRequest, getActiveProviderObjectPanics, togglePanicDetailsModal } from '../../../actions/panic';
import { IPanicObjectProps, IProviderObject, PanicStatus } from '../../../types';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from '../../../translations/enum';



interface Agent {
  agentId: string;
  name: string;
  duration: string;
  distance: string;
  providerId: number
  providerName: string
}

interface AgentModalProps {
  isOpen: boolean;
  isActive: boolean;
  onClose: () => void;
  agents: Agent[];
  providerObject?: IProviderObject | null
  panic?: IPanicObjectProps | null
}

const AddObjectAgentModal: React.FC<AgentModalProps>  = ({ isOpen, isActive = false, onClose, agents, providerObject, panic }) => {
  const classes = useAgentModalStyles();
  const dispatch = useDispatch();
  const [inputLabel, setInputLabel] = useState('');
  const [selectedAgent, setSelectedAgent] = useState('');
  const [agentsList, setAgents] = useState<Agent[]>(agents);
  const [providersObject, setProvidersObject] = useState<any>(providerObject);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (agents?.length && providerObject) {
      setAgents(agents);
      setProvidersObject(providerObject);
      setInputLabel(providerObject?.code);
      setSelectedAgent(`${agents[0].agentId}`);
    }
  }, [agents]);

  const handleResetForm = () => {
    if (isActive) {
      setSelectedAgent('');
    } else {
      setInputLabel('');
      setSelectedAgent('');
      setProvidersObject('');
      setAgents([])
      setErrorMessage(null);
    }
    onClose();
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputLabel(e.target.value);
    setErrorMessage(null);
  };
  const handleAgentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAgent(String(event.target.value));
  };

  const handleFetchProviders = async () => {
    if (panic && agents.length && selectedAgent) {
      const providerId = agents.find((agent) => String(agent.agentId) === selectedAgent)?.providerId;
      const params = {
        id: panic.id,
        data: {
          agentId: +selectedAgent,
          providerId,
          reassign: panic.status === PanicStatus.deassigned,
        },
      }
      await dispatch(assignAgentRequest({ params }));
      return dispatch(getActiveProviderObjectPanics());
    }
    if (!agentsList.length) {
      try {
        const { agents, providersObject } = await api.request('get', `${api.apiBase}/v1/providers-object/panic/agents?code=${inputLabel}`)
        setAgents(agents);
        setProvidersObject(providersObject);
        setSelectedAgent(`${agents[0]?.agentId}`);
        setErrorMessage(null);
      } catch (error: any) {
        setErrorMessage(error.response?.data?.message || 'An error occurred while fetching providers.');
      }
    } else {
      const providerId = agentsList.find((agent) => String(agent.agentId) === selectedAgent)?.providerId;
      try {
        const { id = null } = await api.request('post', `${api.apiBase}/v1/providers-object/panic/trigger`, { agentId: selectedAgent, code: inputLabel, providerId: providerId })
        handleResetForm();
        await dispatch(getActiveProviderObjectPanics());
        dispatch(togglePanicDetailsModal({ id, show: true }))
      } catch (error: any) {
        if (error.response?.data?.message === TranslationKeys.BUSY_AGENT) {
          const updatedList = agentsList.filter((agent) => String(agent.agentId) !== selectedAgent)
          setAgents(updatedList);
          setSelectedAgent(`${updatedList[0].agentId}`);
        }
        setErrorMessage(error.response?.data?.message || 'An error occurred while fetching providers.');
      }
    }
  };

  return (
    <Modal open={isOpen} onClose={handleResetForm}>
      <Box className={classes.modalContainer}>
        <IconButton className={classes.closeButton} onClick={handleResetForm}>
          <CloseIcon />
        </IconButton>
        <Box className={classes.modalBody}>
          {!agentsList?.length && (
            <Typography variant="body2" className={classes.title}>
              <FormattedMessage id="providerObject.insert_code" />
            </Typography>
          )}
          <TextField
            className={!errorMessage ? classes.inputLabel : classes.inputLabelError}
            value={inputLabel}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            disabled={!!agentsList?.length}
            error={!!errorMessage}
          />
          {errorMessage && (
            <FormHelperText error style={{ textAlign: 'center', marginTop: '8px', fontSize: '14px' }}>
              {[TranslationKeys.WRONG_CODE, TranslationKeys.NO_AGENTS_IN_AREA, TranslationKeys.BUSY_AGENT]
                .includes(errorMessage) ? <FormattedMessage id={`backend_errors.${errorMessage}`} /> : errorMessage}
            </FormHelperText>
          )}
          {providersObject && (
            <>
              <Typography variant="body2" className={classes.subtitle}>
                {providersObject.name}
              </Typography>
              <Typography variant="body2" className={classes.subtitle}>
                <FormattedMessage id="providerObject.team" />: {providersObject.team.toUpperCase()}
              </Typography>
            </>
          )}
        </Box>
        <RadioGroup value={selectedAgent} onChange={handleAgentChange}>
          <div
            style={{
              maxHeight: agentsList.length > 5 ? '200px' : 'auto',
              overflowY: agentsList.length > 5 ? 'auto' : 'hidden',
            }}
          >
            {agentsList?.map((agent) =>
              <FormControlLabel
                key={agent.agentId}
                value={`${agent.agentId}`}
                control={<Radio />}
                label={`${agent.providerName.toUpperCase()} - ${agent.name.toUpperCase()} - ${Math.floor(+agent.duration / 60)} min (${
                  agent.distance
                } km)`}
                className={`${classes.radioLabel} ${selectedAgent === `${agent.agentId}` ? 'Mui-checked' : ''}`}
              />
            )}
          </div>
        </RadioGroup>
        <Box textAlign="center">
          <Button
            variant="contained"
            className={classes.assignButton}
            disabled={agentsList.length ? !selectedAgent : !inputLabel}
            onClick={handleFetchProviders}
          >
            {!agentsList?.length ? <FormattedMessage id="providerObject.search_team" /> :
              isActive ? <FormattedMessage id="providerObject.assign" /> : <FormattedMessage id="providerObject.send_panic" />}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddObjectAgentModal;
