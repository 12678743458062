import React from 'react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import PanicMarker from '../markers/panic-marker';
import PanicAllAgentMarkers from '../markers/panic-all-agent-markers';
import { IAppState, PanicStatus } from 'types';
import { useSelector } from 'react-redux';
import AssignedAgentMarker from '../markers/assigned-agent-marker';
import RouteHistoryMarkers from '../markers/route-history-markers';
import { ACTIVE_PANICS, DEASSIGNABLE_PANICS, RESOLVED_PANICS } from 'constants/panic';
import ObjectMarker from '../markers/object-marker';

const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY

type Props = {
  panicId: number;
}

const PanicMiniMap = ({ panicId }: Props) => {

  const panicDetails = useSelector(({ panic }: IAppState) => panic.byId[panicId] || {});
  const clientLiveCoords = useSelector(({ client }: IAppState) => client.byId[panicDetails.userId]);

  const showAssignedAgent = ACTIVE_PANICS.includes(panicDetails.status)

  const showPendingAgents = [PanicStatus.pending, PanicStatus.deassigned].includes(panicDetails.status);

  const isPanicStatusOngoing = !RESOLVED_PANICS.includes(panicDetails.status);
  const showRouteHistory = panicDetails.status === PanicStatus.done;

  const panicCenter = isPanicStatusOngoing && clientLiveCoords || {
    lat: Number(panicDetails.userLat),
    lng: Number(panicDetails.userLon)
  }
  if (!apiKey) {
    return null;
  }

  // @TODO:
  // in case panic is completed (or there are no agents) need to set center to this panic with specified default zoom
  // in case panic is pending: same as above
  // in case panic is assigned: need to fit bounds to panic and assigned agent
  //
  return (
    <APIProvider apiKey={apiKey}>
      <Map defaultCenter={panicCenter} defaultZoom={13} id="mini-map" mapId={'mini-google-map'}>
        {panicDetails.objectId ? <ObjectMarker panicId={panicId} /> : (
          <>
            {showRouteHistory ? (
              <RouteHistoryMarkers userName={panicDetails.user.fullName} agentName={panicDetails.agent.fullName} panicId={panicId}/>
            ) : (
              <PanicMarker panicId={panicId} />
            )}
          </>
        )}
        {showAssignedAgent && <AssignedAgentMarker panicId={panicId} showUnassign={DEASSIGNABLE_PANICS.includes(panicDetails.status)} />}
        {showPendingAgents && <PanicAllAgentMarkers panicId={panicId} panicStatus={panicDetails.status} />}
      </Map>
    </APIProvider>
  )
}

export default PanicMiniMap;
